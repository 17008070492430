<script>
export default {
  props: {
    value: {
      type: String
    }
  },
  created(){

  },
  methods:{
    checkSymbol ($event) {
     
      //console.log($event.keyCode); //keyCodes value
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 43) { 
          $event.preventDefault();
        }
    },
    handleInput (newValue) {
      this.$emit('changed', newValue);
    }
  }
}
</script>

<template>
 
  <input @keypress="checkSymbol" type="text" :value="value" class="form-control" @input="handleInput($event.target.value)" />
</template>