<script>
import { layoutComputed } from "@/state/helpers";
import MetisMenu from "metismenujs/dist/metismenujs";
import { mapGetters } from 'vuex';
import { SimpleBar } from "simplebar-vue3";

/**
 * Sidebar component
 */
export default {
   mounted: function() {
    // eslint-disable-next-line no-unused-vars
    var menuRef = new MetisMenu("#side-menu");
    var links = document.getElementsByClassName("side-nav-link-ref");
    var matchingMenuItem = null;
    const paths = [];

    for (var i = 0; i < links.length; i++) {
      paths.push(links[i]["pathname"]);
    }
    var itemIndex = paths.indexOf(window.location.pathname);
    if (itemIndex === -1) {
      const strIndex = window.location.pathname.lastIndexOf("/");
      const item = window.location.pathname.substr(0, strIndex).toString();
      matchingMenuItem = links[paths.indexOf(item)];
    } else {
      matchingMenuItem = links[itemIndex];
    }

    if (matchingMenuItem) {
      matchingMenuItem.classList.add("active");
      var parent = matchingMenuItem.parentElement;

      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.classList.add("mm-active");
        const parent2 = parent.parentElement.closest("ul");
        if (parent2 && parent2.id !== "side-menu") {
          parent2.classList.add("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.add("mm-active");
            var childAnchor = parent3.querySelector(".has-arrow");
            var childDropdown = parent3.querySelector(".has-dropdown");
            if (childAnchor) childAnchor.classList.add("mm-active");
            if (childDropdown) childDropdown.classList.add("mm-active");

            const parent4 = parent3.parentElement;
            if (parent4 && parent4.id !== "side-menu") {
              parent4.classList.add("mm-show");
              const parent5 = parent4.parentElement;
              if (parent5 && parent5.id !== "side-menu") {
                parent5.classList.add("mm-active");
                const childanchor = parent5.querySelector(".is-parent");
                if (childanchor && parent5.id !== "side-menu") {
                  childanchor.classList.add("mm-active");
                }
              }
            }
          }
        }
      }
    }
  },
  components: {
     SimpleBar,
  },
  props: {
    isCondensed: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...layoutComputed,
    ...mapGetters('auth', {
      currentUser: 'currentUser',
      userGroupIDs: 'userGroupIDs',
      mapEnabled: 'mapEnabled'
    }),
    ...mapGetters('usageProfiles', {
      currentUsageProfile: 'current'
    }),
    ...mapGetters('directories', {
      directories: 'items',
      mapDirectories: 'directoriesOnMap'
    }),
    ...mapGetters('boards', {
      boards: 'items'
    }),
    filteredDirectories() {
      return this.directories.filter(x => x.showOnMenu.find(groupId => this.userGroupIDs.indexOf(groupId) >= 0));
    },
    
    ...mapGetters('chat', {
      unreadMessagesCount: 'unreadMessagesCount'
    }),

    menuItems() {
      if(!this.currentUsageProfile) {
        return [];
      }
      const items = [];
      this.currentUsageProfile.menu.forEach(x => {
        if(x.type === 'Directory') {
          const dir = this.directories.find(dir => dir.id === x.target);
          if(dir) { 
            items.push({
              icon: dir.type === 'Process' ? 'fa-solid fa-circle-nodes' : 'fa-solid fa-database',
              title: dir.name,
              url: '/directories/' + dir.id + '/items',
              key: 't-directory-' + dir.id,
              isActive: () => this.isActiveDirectotyItemsMenuItem(dir)
            });
          }
        } else if(x.type === 'Map') {
          items.push({
            icon: 'fa-solid fa-map',
            title: this.$t('common.map'),
            url: '/map',
            key: 't-map',
            isActive: () => this.$route.name === 'map'
          });
        } else if(x.type === 'Board') {
          const board = this.boards.find(b => b.id === x.target);
          if(board) {
            items.push({
              icon: 'fa-chalkboard',
              title: board.name,
              url: '/boards/' + board.id + '/view',
              key: 't-boards-' + board.id,
              isActive: () => this.isBoardMenuItemActive(board)
            });
          }
        } else if(x.type === 'Report' && x.target === 'process-report-1') {
          items.push({
              icon: 'fa-bar-chart',
              title: 'Кількість наданої допомоги',
              url: '/process-reports',
              key: 'process-reports',
              isActive: () => this.isReportMenuItemActive(x)
            });
        } else if(x.type === 'Report' && x.target === 'request-report-1') {
          items.push({
              icon: 'fa-bar-chart',
              title: 'Кількість бажаної допомоги',
              url: '/request-reports',
              key: 'request-reports',
              isActive: () => this.isReportMenuItemActive(x)
            });
        } else if(x.type === 'Report' && x.target === 'heat-map-1') {
          items.push({
              icon: 'fa-map',
              title: 'Теплова мапа',
              url: '/heat-map-1',
              key: 'heat-map-1',
              isActive: () => this.isReportMenuItemActive(x)
            });
        }
      });
      return items;
    },
    isChatEnabled() {
     // console.log('a', process.env.VUE_APP_CHAT_ENABLED);
      return this.currentUser && this.currentUser.account.settings.moduleChatBotEnabled;
    },
    // isFileStorageEnabled() {
    //  // console.log('a', process.env.VUE_APP_CHAT_ENABLED);
    //   return this.currentUser && this.currentUser.account.settings.moduleFileStorageEnabled;
    // },
    
    // isMap() {
    //   return this.$route.name === 'map';
    // },
    // isHome() {
    //   return this.$route.name === 'home';
   // },
    isChat() {
      return this.$route.name === 'chat' || this.$route.name === 'bot-registration' || this.$route.name === 'bots';
    }
    // isContacts() {

    //  // console.log('isContacts', this.$route.name);
    //   return this.$route.name === 'contacts' 
    //   || this.$route.name === 'contact-details'
    //   || this.$route.name === 'contact-custom-fields';
    // },
    
    // isProcesses() {
    //   return this.$route.name === 'process' || this.$route.name === 'process-list' 
    // }
  },
  data() {
    return {
      settings: {
        minScrollbarLength: 60,
      },
    };
  },
  watch: {
    $route: {
      handler: "onRoutechange",
      immediate: true,
      deep: true,
    },
    type: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          switch (newVal) {
            case "default":
              document.body.setAttribute("data-sidebar-size", "lg");
              break;
            case "compact":
              document.body.setAttribute("data-sidebar-size", "md");
              document.body.classList.remove("vertical-collpsed");
              break;
            case "icon":
              document.body.setAttribute("data-sidebar-size", "sm");
              break;
            default:
              document.body.setAttribute("data-sidebar-size", "lg");
              break;
          }
        }
      },
    },
    width: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          switch (newVal) {
            case "boxed":
              document.body.setAttribute("data-layout-size", "boxed");
              break;
            case "fluid":
              document.body.setAttribute("data-layout-size", "fluid");
              break;
            default:
              document.body.setAttribute("data-layout-size", "fluid");
              break;
          }
        }
      },
    },
    color: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          switch (newVal) {
            case "light":
              document.body.setAttribute("data-sidebar", "light");
              break;
            case "dark":
              document.body.setAttribute("data-sidebar", "dark");
              break;
            case "brand":
              document.body.setAttribute("data-sidebar", "brand");
              break;
            default:
              document.body.setAttribute("data-sidebar", "dark");
              break;
          }
        }
      },
    },
  },
  methods: {
    isReportMenuItemActive(item) {
      if(this.$route.name === 'process-reports' && item.target === 'process-report-1') {
        return true;
      } else  if(this.$route.name === 'request-reports' && item.target === 'request-report-1') {
        return true;
      } else  if(this.$route.name === 'request-reports' && item.target === 'heat-map-1') {
        return true;
      }
      return false;
    },
    isBoardMenuItemActive(board) {
      if(this.$route.name === 'board-view') {
        
        if(this.$route.params.id === board.id) {
          return true;
        }
      }
      return false;
    },
    isActiveDirectotyItemsMenuItem(dir) {
      
      if(this.$route.name === 'directory-items'){
        
        if(this.$route.params.type === dir.id) {
          return true;
        }
      }
      return false;
    },
   isActiveProcessMenuItem(pt) {
      
      if(this.$route.name === 'process' || this.$route.name === 'process-list'){
        
        if(this.$route.params.id === pt.id) {
          return true;
        }
      }
      return false;
    },
    onRoutechange() {
      setTimeout(() => {
        if(document.getElementsByClassName("mm-active").length > 0) {
        const currentPosition = document.getElementsByClassName("mm-active")[0].offsetTop;
        if (currentPosition > 500)
          if(this.$refs.isSimplebar)
            this.$refs.isSimplebar.value.getScrollElement().scrollTop = currentPosition + 300;
        }
      }, 300);
    },
    toggleMenu() {
      this.$parent.toggleMenu();
    },
  },
};
</script>

<template>
<!-- ========== Left Sidebar Start ========== -->
<div class="vertical-menu">

    <!-- LOGO -->
    <div class="navbar-brand-box">
      <router-link to="/" class="logo logo-dark">
        <span class="logo-sm">
            <img src="@/assets/images/logo-dark-sm.png" alt="" height="22">
        </span>
        <span class="logo-lg">
            <img src="@/assets/images/logo-dark.png" alt="" height="22">
        </span>
      </router-link>

      <router-link to="/" class="logo logo-light">
          <span class="logo-lg">
              <img src="@/assets/images/logo-light.png" alt="" height="22">
          </span>
          <span class="logo-sm">
              <img src="@/assets/images/logo-light-sm.png" alt="" height="22">
          </span>
      </router-link>
    </div>

    <button type="button" class="btn btn-sm px-3 header-item vertical-menu-btn topnav-hamburger" @click="toggleMenu">
      <span class="hamburger-icon">
        <span></span>
        <span></span>
        <span></span>
      </span>
    </button>

    <SimpleBar data-simplebar class="sidebar-menu-scroll" ref="isSimplebar">
      <!--- Sidemenu -->
      <div id="sidebar-menu">
          <!-- Left Menu Start -->
          
          <ul class="metismenu list-unstyled" id="side-menu">
            <li v-for="menuItem in menuItems" :key="menuItem.key" :class="{'mm-active': menuItem.isActive()}">
              <router-link class="side-nav-link-ref text-nowrap text-truncate" :to="menuItem.url" :data-key="menuItem.key">
                <font-awesome-icon :icon="menuItem.icon" class="me-2"/>
                {{menuItem.title}}
              </router-link>
            </li>

            <!-- <li data-key="t-home">
              <router-link class="side-nav-link-ref  text-nowrap text-truncate" to="/" data-key="t-home">
                <i class=" font-size-18 me-1 mdi mdi-home " style="width:24px"></i>
                {{$t('common.home')}}
              </router-link>
            </li> -->

            <!-- <li :class="{'mm-active': isMap}" v-if="isMapMenuVisible">
              <router-link class="side-nav-link-ref text-nowrap text-truncate" to="/map">
                <font-awesome-icon icon="fa-solid fa-map" class="me-2"/>
                {{$t('common.map')}}
              </router-link>
            </li>  -->

            <!-- <li v-for="dir in filteredDirectories" :key="dir.id" :class="{'mm-active': isActiveDirectotyItemsMenuItem(dir)}"  :data-key="dir.id">
              <router-link class="side-nav-link-ref text-nowrap text-truncate" :to="{name: 'directory-items', params: {type:dir.id}}">
                <font-awesome-icon icon="fa-solid fa-database" class="me-2"  v-if="dir.type === 'Data'" />
                <font-awesome-icon icon="fa-solid fa-circle-nodes" class="me-2"  v-if="dir.type === 'Process'"/>
                {{dir.name}}
              </router-link>
            </li>
             -->


             <!-- -->
            <!-- <li :class="{'mm-active': isChat}" v-if="isChatEnabled">
              <router-link class="side-nav-link-ref text-nowrap text-truncate" to="/chat">
                <i class=" font-size-18 me-1 mdi mdi-chat" style="width:24px"></i>
                {{$t('common.chat')}}
                <span class="badge rounded-pill bg-danger" v-if="unreadMessagesCount > 0"> {{unreadMessagesCount}}</span>
              </router-link>
            </li>  -->

          
            
            <!-- <li class="menu-title" data-key="t-menu"> {{$t('common.processEntityPlural')}}</li>
            <li v-for="pt in processTypes" :key="pt.id" :class="{'mm-active': isActiveProcessMenuItem(pt)}"  :data-key="pt.id">
              <router-link class="side-nav-link-ref text-nowrap text-truncate" :to="{name: processView === 'board' ? 'process' : 'process-list', params: {id:pt.id}}">
                <i class="font-size-18 me-1 mdi mdi-label-variant"></i>
                {{pt.name}}
              </router-link>
            </li>  -->

           
            <!-- <li class="menu-title" data-key="t-menu">{{$t('common.dataSectionTitle')}}</li>
            <li>
              <router-link class="side-nav-link-ref text-nowrap text-truncate" to="/contacts" data-key="t-contacts">
                 <i class=" font-size-18 me-1 mdi mdi-card-account-details-outline" style="width:24px"></i>
                {{$t('common.contactEntityPlural')}}
              </router-link>
            </li> -->
            

            <!-- <li class="menu-title" data-key="t-menu" v-if="isFileStorageEnabled">File Storage</li>
            <li v-if="isFileStorageEnabled">
              <router-link class="side-nav-link-ref text-nowrap text-truncate" to="/files" data-key="t-files">
                 <i class=" font-size-18 me-1 mdi mdi-file-document-multiple-outline"></i>
                Files
              </router-link>
            </li> -->
            <!-- <li v-for="dir in filteredDirectories" :key="dir.id" :class="{'mm-active': isActiveDirectotyItemsMenuItem(dir)}"  :data-key="dir.id">
              <router-link class="side-nav-link-ref text-nowrap text-truncate" :to="{name: 'directory-items', params: {type:dir.id}}">
                <i class="font-size-18 me-1 mdi mdi-database" style="width:24px"></i>
                {{dir.name}}
              </router-link>
            </li>
            <li class="menu-title" data-key="t-menu">{{$t('common.other')}}</li>
            <li>
              <router-link class="side-nav-link-ref text-nowrap text-truncate" to="/reminders" data-key="t-reminders">
                <i class=" font-size-18 me-1 mdi mdi-alarm-multiple" style="width:24px"></i>
                {{$t('common.reminders')}}
              </router-link>
            </li> -->
            <!-- <li>
              <router-link class="side-nav-link-ref text-nowrap text-truncate" to="/reports" data-key="t-reports">
                <font-awesome-icon icon="fa-solid fa-bar-chart"  class="me-2" />
                {{$t('common.reports')}}
              </router-link>
            </li> -->

            <li class="menu-title" data-key="t-menu" v-if="currentUser.isAdmin">{{$t('common.administration')}}</li>
            <li  v-if="currentUser.isAdmin">
              <router-link class="side-nav-link-ref text-nowrap text-truncate" to="/users" data-key="t-users">
                <font-awesome-icon icon="fa-solid fa-user-gear"  class="me-2" />
                {{$t('common.userEntityPlural')}}
              </router-link>
            </li>
            <li  v-if="currentUser.isAdmin">
              <router-link class="side-nav-link-ref text-nowrap text-truncate" to="/user-groups" data-key="t-usergroups">
                <font-awesome-icon icon="fa-solid fa-users-gear" class="me-2"/>
                {{$t('userGroups.pageTitle')}}
              </router-link>
            </li>
            <li v-if="currentUser.isAdmin">
              <router-link class="side-nav-link-ref text-nowrap text-truncate" to="/directories" data-key="t-users">
                <font-awesome-icon icon="fa-solid fa-gears" class="me-2"/>
                {{ $t("directory.pageTitle") }}
              </router-link>
            </li>
            <li v-if="currentUser.isAdmin">
              <router-link class="side-nav-link-ref text-nowrap text-truncate" to="/usage-profiles" data-key="t-usage-profiles">
                <font-awesome-icon icon="fa-solid fa-id-card-clip"  class="me-2"/>
                {{ $t('usageProfile.pageTitle') }}
              </router-link>
            </li>
            <li v-if="currentUser.isAdmin">
              <router-link class="side-nav-link-ref text-nowrap text-truncate" to="/boards" data-key="t-boards">
                <font-awesome-icon icon="fa-solid fa-chalkboard" class="me-2"/>
                {{ $t('board.pageTitle') }}
              </router-link>
            </li>
            <li v-if="currentUser.isAdmin">
              <router-link class="side-nav-link-ref text-nowrap text-truncate" to="/settings" data-key="t-settings">
                <font-awesome-icon icon="fa-solid fa-screwdriver-wrench"  class="me-2"/>
                {{ $t('settings.pageTitle') }}
              </router-link>
            </li>
           
             <!-- <li v-if="currentUser.isAdmin">
              <router-link class="side-nav-link-ref text-nowrap text-truncate" to="/process-types" data-key="t-users">
                <i class=" font-size-18 me-1 mdi mdi-cogs" style="width:24px"/>
               {{$t('common.processEntityPlural')}}
              </router-link>
            </li> -->
          </ul>
      </div>
      <!-- Sidebar -->        
    </SimpleBar>
</div>
<!-- Left Sidebar End -->
</template>
